<template>
    <div>
      <h1>404</h1>
      <router-link :to="{name: 'home'}">Go back to home</router-link>
    </div>
</template>

<script>
export default {
  name: 'Error404',
  mounted () {
    window.location.href = '/404/'
  }
}

</script>

<style scoped>

</style>
